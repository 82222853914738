:root {  
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;    
     /*General*/
    --container-max-width: 1300px;
    --fixed-header-height: 80px; 
    /* Slick */
    --slick-slider-height: 45vh;
    /* change settings for headings */
    --text-color: #222;
    --body-bg: #fff;
    --main-color: #eee;
    --accent-color: #1b75bc;
    --effect-color: #414142;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --menu-bg-color: inherit;
    --menu-bg-hover: var(--accent-color);
    --menu-li-a-padding: 10px 15px;
    --link-color: inherit;
    --link-hover-color: inherit;
    --map-marker-color: 000;    
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 15px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;   
    /* Fonts */
    --base-font-family: 'Calibri', 'Playfair Display', Helvetica, Arial, sans-serif;
    --alternative-font-family: 'Calibri', 'Playfair Display', Helvetica, Arial, sans-serif;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Noto Sans/all.css"; /* 400, 400i, 700, 700i */
@import "/fonts/Playfair Display/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 500, 500i, 700, 700i, 900, 900i */
/*@import "/cssmodules/animate.css";*/

/*--------------------------------------------
General
---------------------------------------------*/
.inner-content {
   min-width:700px;
}
.box-inner p {
    margin: 0 !important;
    padding: 0 !important;
}
.box-inner h2{
    margin:0.1rem;
}
.news-block-inner.flex.margin a{
    background:#3a5998;
    color:#fff;
}
.news-block-inner{
background:#f6f6f6;
        border: 10px solid #3a5998;
}

.news-item flex a{background:none; border-bottom: 5px solid #3a5998;}
a.fbbutton{
    font-size: 1.2rem;
    width:100%;
    text-align:center;
    padding: .1em 1em;
}
.fb-like{
    margin:1em 0;
}
a.fbbutton {
    max-height: 30px;
        background:#3a5998;
    color:#fff;
}
.toggleelement {display:none;}

img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height: 100%;
}

body {
    /*background: url('/theme/intendit/graphics/bg.png'); */
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4 {
    font-family: var(--alternative-font-family);
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h1.twelve {
    max-width: 100%;
}

.container h1 {
    margin: 2rem 0.8rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels {
    overflow: visible !important;
}

.fa-map-marker {
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*--------------------------------------------------------
Header
---------------------------------------------------------*/
header {
    background: rgba(255,255,255,.8);
}

header .container {
    padding: 0 0.5em;
}

header a {
    text-decoration: none;
}

.logo img {
    padding: 1rem 0;
}


.menu li {
    margin: 0;
    margin-bottom: 1rem;
}

.menu li a {
    padding: var(--menu-li-a-padding);
    color: #777;
    transition: 0.5s ease-in;
    font-family: var(--alternative-font-family);
    font-size: 1.3rem;
}

.homepage .menu li a {
    color: var(--effect-color);
    text-shadow: 1px 1px 0 #fff;
}

.menu li a:hover, .menu .active a {

    color: #222;
}

/**** Dropdown ****/

.menu .dropdown ul {
    z-index: 100;
    border-bottom: none;
}

.menu li:hover ul {
    margin-left: 0;
}

.menu .sub-menu-items li a {
    margin: 0;
    background: var(--main-color);
    color: #222;
}

.menu.menu .sub-menu-items li a:hover {
    background: var(--accent-color);
    color: #fff;
}

/**** Leftmenu ****/

.submenu ul {
    margin-top: 2.8rem;
    padding: 0;
}

.submenu ul li {
    list-style-type: none;
    padding: var(--padding);
    border-bottom: 1px solid #eee;
    background: #fff;
}

.submenu ul li a {
    text-decoration: none;
    color: var(--effect-color);
}

.submenu li a:hover, .submenu .active a {
    color: var(--accent-color);
}

/*--------------------------------------------------------
Bild startsida
---------------------------------------------------------*/

.topimg {
    height: 70vh;
    background-position: 70% 50%;
    margin-top: -110px;
    background-size: cover;
}

 .boxes:before {
    background-image: url("/theme/intendit/graphics/topframe.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    height: 130px;
    left: 50%;
    margin: -130px 0 0 -50%;
    position: absolute;
    width: 100%;
}



/* Content-startpage | Blocks
---------------------------------------------------------*/

.intro-content {
    transform: translateY(-350px);
    max-width: 40vw;
    position: absolute;
    left: 2rem;
}

.inner-content h1, .inner-content h2 {
    padding: 0;
    margin: 0;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 100;
    font-family: var(--alternative-font-family);
    /*text-shadow: 1px 1px 0 rgba(255,255,255,.6); */
    animation: 1s linear teaserAnimation both;
    /*transform: translateY(10px);*/
    opacity: 0;
    will-change: transform;
    margin-bottom: 2rem;
}

.inner-content h1 {
    animation-delay: .8s;
}

.inner-content h2 {
    animation-delay: 4s;
}

@keyframes teaserAnimation {
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


/* Content - all
---------------------------------------------------------*/

.imagetop-outer {
    padding: 10px;
    background: #fff;
}

.imagetop {
    height: 30vh;
    background: no-repeat top right;
    background-size: cover;
}

.ledningssystem-item {
    background: #e8f7ff;
}




.ledningssystem-inner {
    padding: 2rem;
}

.ledningssystem-item .readmore {
    background: var(--effect-color);
    color: #fff;
    transition: .5s ease;
    border: none;
    border-radius: 0;
}

.ledningssystem-item .readmore:hover {
    background: #222;
}

.ledningssystem-item .readmore a {
    text-decoration: none
}

.utbildning-item {
    background: #e8f7ff;
    color: #222;
}

.utbildning-item-button {
    display: flex;
    align-items: flex-end;
}

.utbildning-item-button .button {
    background: var(--effect-color);
    color: #fff;
}

.utbildning-item .button:hover {
    color: #fff;
    background: var(--accent-color);
}

.utbildning-item h3 {
    font-family: var(--base-font-family);
    font-size: 1.1rem;
    margin: 0;
}

.teaser {
    font-weight: 300;
    font-size: 1.5rem;
    text-align: center;
    animation: keywords 1.5s;
}


.newsblock {
    margin-bottom: 2rem;
    background: var(--main-color);
}

.newsblock h2 {
    text-align: center;
}

.publishdate {
    
}

.newsitem {
    margin-bottom: 1rem;
    padding: 1rem;
}

.newsheading {
    font-weight: 700;
}

.newsblock .button, .newsblock button, input[type=reset], input[type=submit]  {
    border: none;
    background: var(--accent-color);
    color: #fff;
    margin-left: 0;
    border-radius: 5px;
    transition: .5s ease;
    text-align: center;
    float: right;
}

.newsblock .button a {
    color: #fff;
    text-decoration: none;
}



@keyframes keywords {
    0% {
        opacity: 0;
        transform: scaleY(.8) translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: scaleY(1) translateX(0px);
    }
}


article ul li {
    list-style-type: none;
}

article ul li:before {
    content: "\2714";
    margin: 0 .3em 0 0;
    font-size: 1.2em;
    color: var(--text-color);
}

/* Boxar / Zoomboxar
-------------------------------------------------*/

/*ikoner*/
.round .fa-icon {
    height: 20px;
    width: auto;
    vertical-align: middle !important;
}

.round {
    background: var(--accent-color);
    border-radius: 50%;
    display: inline-block;
    height: 95px;
    width: 95px;
    margin: 0 10px 10px 0;
    position:relative;
}
.round img{
    position:absolute;
    top:25%;
    left:25%;
}

.round .fa-icon {
    fill: #fff;
    padding: 0px 10px;
    height: 45px;
    width: 45px;
    width: auto;
    display: block;
    margin: 0 auto;
    margin-top: 23px;
}

.round .fa-icon[aria-label="Icon depicting mobile"] {
    padding: 3px 0;
}

.boxwrapper {
    background-size: 0;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: #222;
    align-items: flex-start;
    transform: translateY(-50px);
    padding-top: 70px;
}

.boxwrapper:hover {
    transform: translateY(-100px);
}

.boxwrapper[style]:before {
    content: '';
    transition: transform .3s ease-in 0s;
}

.boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .3s ease-in 0s;
    z-index: 2;
}

.box-inner {
    text-align: center;
}

.zoombox .boxbutton {
    z-index: 101;
    opacity: 0;
    text-align: center;
    transition: 0.5s ease;
    color: #222;
}

.zoombox .boxwrapper:hover .boxbutton {
    opacity: 1;
}

.boxbutton .button {
    background: var(--accent-color);
    color: #fff;
    text-decoration: none;
    padding: .5rem 1rem;
    border-radius: 5px;
}


/* Contact
---------------------------------------------------------*/


.kontakt svg {
    margin-right: 0.5rem;
}


/* listing items
---------------------------------------------------------*/

.listing-item {
    margin-right: 0;
    padding-right: 0;
    background: var(--main-color);
    margin-bottom: 10px;
}


.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color: white;
}

.pagination ul li a {
    text-decoration: none;
}

/* Footer
---------------------------------------------------------*/

footer {
    background: linear-gradient(0deg,rgba(27,117,188,.6),rgba(27,117,188,.6)),url('/theme/intendit/graphics/footerbg.jpg');
    background-position: 60% 30%;
    color: #fff;
}

footer a {
    text-decoration: none;
}

footer svg.fa-icon {
    fill: currentColor;
    height: calc(1 * var(--scale-factor)rem);
    transition: ease-in 0.5s;
}

footer svg.fa-icon:hover {
    fill: var(--accent-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/* Intendit footer
-------------------------------------------------*/
.created-by {
    background: var(--effect-color);
    padding: 0.5em 0;
    text-align: center;
}

.created-by .container, .intenditfooter {
    padding: 0;
    margin: 0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a {
    color: #777;
}


/*------------------------------------------
Sticky footer
-------------------------------------------*/
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
 /* IE fix */;
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin: 0;
}


/* general
---------------------------------------------------------*/

.contactform .boltform textarea {
    min-height: 120px;
}

.contactform .boltforms-row {
    margin: .2rem;
}

input[type=email], input[type=number], input[type=search], input[type=text], input[type=tel], input[type=url], input[type=password], textarea {
    width: 100%;
    color: #222;
    padding: 1rem;
}

button[type=submit], input[type=submit] {
    margin: 0;
    background: var(--accent-color);
    float: right;
    border: none;
    transition: ease-in 0.5s;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: var(--effect-color);
    color: #fff;
    border: none;
}

.fa-map-marker {
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}

/*Not found*/
.pagenotfound {
    min-height: 100vh;
    position: relative;
    text-align: center;
    font-size: 1.1em;
}

.pagenotfound .logo img {
    max-height: 70px;
    width: auto;
    max-width: 100%;
    display: block;
}

.pagenotfound ul {
    list-style-type: none;
    padding: 0;
    margin: 0.5em auto;
    width: 100%;
    max-width: 550px;
}

.pagenotfound li a {
    border-bottom: 1px dashed #d0d0d0;
    display: inline-block;
    padding: 10px 110px;
}

/* Media Queries
---------------------------------------------------------*/

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    .inner-content{min-width:400px;}
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }

    .intenditfooter {
        text-align: center;
    }
    .contact-padding {
        display:block;
        padding: 0.3rem 0;
    }

}


@media (max-width: var(--breakpoint-xs)) {
    
    footer {
        text-align: center;
    }
    
}

@media (max-width: var(--breakpoint-menu)) {
   
    .menu .dropdown ul {
        border: none !important;
        background: var(--accent-color) !important;
        width: 100%;
        padding-left: 30px;
    }

    .menu .dropdown ul li a {
        font-size: .8rem;
    }

    .menu li, .menu li ul, .menu li:hover ul {
        margin: 0 !Important;
    }

    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
        background: var(--accent-color);
        color: #fff;
    }

    .menu .active a {
        color: #fff;
        background: var(--effect-color) !important;
    }
    
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border: 1px solid;
    }
}
